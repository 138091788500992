import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,
  Box, Button, TextField, Switch, Chip, MenuItem, IconButton, Menu
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import UserFormPopup from './UserFormPopup';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Ensure you import the db from firebaseConfig

function UsersTable() {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const usersList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleMenuClick = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const handleEditUser = () => {
    console.log('Edit user:', selectedUserId);
    handleMenuClose();
  };

  const handleDeactivateUser = () => {
    console.log('Deactivate user:', selectedUserId);
    handleMenuClose();
  };

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const filteredUsers = users.filter((user) => {
    return (
      (statusFilter === 'All' || user.status === statusFilter) &&
      (user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  const getStatusChip = (status) => {
    switch (status) {
      case 'Active':
        return <Chip label="Active" color="success" />;
      case 'Pending':
        return <Chip label="Pending" color="warning" />;
      case 'Deactivated':
        return <Chip label="Deactivated" color="default" />;
      default:
        return <Chip label={status} />;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Users</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearch}
            sx={{ width: '300px' }}
          />
          <TextField
            label="Filter by Status"
            select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            sx={{ width: '200px' }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Deactivated">Deactivated</MenuItem>
          </TextField>
        </Box>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handlePopupOpen}>
          Add New User
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Events</TableCell>
              <TableCell align="center">Alerts</TableCell>
              <TableCell align="center">Alarms</TableCell>
              <TableCell align="center">Options</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}></TableCell> {/* Empty cells to align with headers */}
              <TableCell align="center">Email / SMS</TableCell>
              <TableCell align="center">Email / SMS</TableCell>
              <TableCell align="center">Email / SMS</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{getStatusChip(user.status)}</TableCell>
                <TableCell align="center">
                  <Box>
                    <Switch checked={user.notifications.emailEvents} disabled />
                    <Switch checked={user.notifications.smsEvents} disabled />
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Switch checked={user.notifications.emailAlerts} disabled />
                    <Switch checked={user.notifications.smsAlerts} disabled />
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Switch checked={user.notifications.emailAlarms} disabled />
                    <Switch checked={user.notifications.smsAlarms} disabled />
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="options"
                    onClick={(event) => handleMenuClick(event, user.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedUserId === user.id}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleEditUser}>
                      <EditIcon sx={{ marginRight: 1 }} />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleDeactivateUser}>
                      <BlockIcon sx={{ marginRight: 1 }} />
                      Deactivate
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* User Form Popup */}
      <UserFormPopup open={isPopupOpen} handleClose={handlePopupClose} />
    </Box>
  );
}

export default UsersTable;
