import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Chip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import AlarmIcon from '@mui/icons-material/Alarm';

const data = [
  { date: '2024-08-21', time: '12:34:43', eventType: 'Alert', unitNumber: '001', manufacturer: 'Caterpillar', model: 'D9', status: 'Low Level' },
  { date: '2024-08-22', time: '14:22:11', eventType: 'Alarm', unitNumber: '002', manufacturer: 'Komatsu', model: 'PC200', status: 'Low Pressure' },
  { date: '2024-08-25', time: '08:15:30', eventType: 'Alarm', unitNumber: '005', manufacturer: 'Hitachi', model: 'ZX350', status: 'Low Pressure' },
];

function getEventTypeChip(eventType) {
  switch (eventType) {
    case 'Alert':
      return <Chip icon={<WarningIcon />} label="Alert" color="warning" />;
    case 'Alarm':
      return <Chip icon={<AlarmIcon />} label="Alarm" color="error" />;
    default:
      return null; // Only show Alert and Alarm chips
  }
}

function EventsTable() {
  // Filter data to only include Alerts and Alarms
  const filteredData = data.filter((row) => row.eventType === 'Alert' || row.eventType === 'Alarm');

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <Typography variant="h6" component="div" sx={{ mb: 2 }}>
        Active Alarms
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell>Unit Number</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{`${row.date} ${row.time}`}</TableCell>
                <TableCell>{getEventTypeChip(row.eventType)}</TableCell>
                <TableCell>{row.unitNumber}</TableCell>
                <TableCell>{row.manufacturer}</TableCell>
                <TableCell>{row.model}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default EventsTable;
