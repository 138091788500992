import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { createUser } from '../controllers/UsersController';

function UserFormPopup({ open, handleClose }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const generateRandomPassword = () => {
    return Math.random().toString(36).slice(-10);
  };

  const checkEmailExists = async (email) => {
    const usersCollectionRef = collection(db, 'users');
    const q = query(usersCollectionRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Check if email already exists in Firestore
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        setError('Email already exists in the database.');
        return;
      }

      // Step 2: Create a new Firebase Auth user
      const auth = getAuth();
      const password = generateRandomPassword();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Step 3: Add the new user's information to Firestore
      const userData = {
        firstName,
        lastName,
        email,
        phoneNumber: null, // Initialize phone number to null
        role: 'User', // Default role
        status: 'Pending', // Default status
        notifications: {
          emailAlerts: false, // Default alert notifications
          smsAlerts: false,
          emailAlarms: false, // Default alarm notifications
          smsAlarms: false,
          emailEvents: false, // Default event notifications (email)
          smsEvents: false, // Default event notifications (SMS)
        },
      };

      await createUser(userData);

      // Clear form and close popup
      setFirstName('');
      setLastName('');
      setEmail('');
      setError('');
      handleClose();

      console.log('User added successfully');
    } catch (err) {
      console.error('Error adding user:', err);
      setError('Failed to add user. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="First Name"
            variant="outlined"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            required
            margin="dense"
          />
          <TextField
            label="Last Name"
            variant="outlined"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            required
            margin="dense"
          />
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            margin="dense"
          />
          {error && (
            <Box sx={{ color: 'red', mt: 2 }}>
              {error}
            </Box>
          )}
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            <Button type="submit" color="primary" variant="contained">Add User</Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UserFormPopup;
