import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

function Logo() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Select the appropriate logo based on the theme
  const logoSrc = isDarkMode ? '/images/logo-white.png' : '/images/logo-black.png';

  return (
    <Box
      component="img"
      src={logoSrc}
      alt="Logo"
      sx={{ height: 40, width: 'auto' }}
    />
  );
}

export default Logo;
