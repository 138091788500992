import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import Equipments from './pages/Equipments'; // Updated import for Equipments page
import Alerts from './pages/Alerts';
import Users from './pages/Users';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { lightTheme, darkTheme } from './theme';
import MainLayout from './components/MainLayout';

function PrivateRoute({ children }) {
  const [loading, setLoading] = React.useState(true);
  const [authenticated, setAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return authenticated ? children : <Navigate to="/signin" />;
}

function App() {
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <MainLayout toggleTheme={toggleTheme}>
                  <Dashboard />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/equipments" // Updated route path
            element={
              <PrivateRoute>
                <MainLayout toggleTheme={toggleTheme}>
                  <Equipments />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/alerts"
            element={
              <PrivateRoute>
                <MainLayout toggleTheme={toggleTheme}>
                  <Alerts />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <MainLayout toggleTheme={toggleTheme}>
                  <Users />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
