import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Box } from '@mui/material';

const data = [
  { day: 'Mon', alerts: 5, alarms: 0 },
  { day: 'Tue', alerts: 2, alarms: 2 },
  { day: 'Wed', alerts: 4, alarms: 0 },
  { day: 'Thu', alerts: 1, alarms: 1 },
  { day: 'Fri', alerts: 5, alarms: 1 },
  { day: 'Sat', alerts: 2, alarms: 3 },
  { day: 'Sun', alerts: 1, alarms: 0 },
];

function ChartComponent() {
  return (
    <Box sx={{ width: '100%', mb: 3, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" component="div" sx={{ mb: 2 }}>
        Alerts and Alarms  "(Past 7 Days)"
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ top: 5, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="alerts" stroke="#FFA600" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="alarms" stroke="#FF0000" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export default ChartComponent;
