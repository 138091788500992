import { getFirestore, collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Importing the Firestore instance from your firebaseConfig.js

const equipmentCollection = collection(db, 'equipments'); // Reference to the 'equipments' collection

export const EquipmentController = {
  // Create a new equipment entry
  async addEquipment({ unitNumber, manufacturer, model, uuid }) {
    try {
      const newEquipment = {
        unitNumber: unitNumber || '', // Unit Number from the form
        manufacturer: manufacturer || '', // Brand (Manufacturer) from the form
        model: model || '', // Model from the form
        uuid: uuid || '', // UUID from the form
        pumpOn: false, // Default value
        alert: false, // Default value
        alarm: false, // Default value
        lastSeen: null, // Initialize last seen to null
        status: 'Pending' // Set the initial status to 'Pending'
      };
      const docRef = await addDoc(equipmentCollection, newEquipment);
      return docRef.id;
    } catch (error) {
      console.error('Error adding equipment: ', error);
      throw error;
    }
  },

  // Get all equipment entries
  async getAllEquipment() {
    try {
      const querySnapshot = await getDocs(equipmentCollection);
      const equipmentList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return equipmentList;
    } catch (error) {
      console.error('Error getting equipment: ', error);
      throw error;
    }
  },

  // Update an equipment entry
  async updateEquipment(id, updatedData) {
    try {
      const equipmentDoc = doc(db, 'equipments', id);
      await updateDoc(equipmentDoc, updatedData);
    } catch (error) {
      console.error('Error updating equipment: ', error);
      throw error;
    }
  },

  // Delete an equipment entry
  async deleteEquipment(id) {
    try {
      const equipmentDoc = doc(db, 'equipments', id);
      await deleteDoc(equipmentDoc);
    } catch (error) {
      console.error('Error deleting equipment: ', error);
      throw error;
    }
  }
};
