import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import Logo from '../components/Logo';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSignIn = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        navigate('/dashboard'); // Redirect to dashboard on successful sign-in
      })
      .catch((error) => {
        setError('Invalid email or password');
      });
  };

  const handlePasswordReset = () => {
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetMessage('Password reset email sent. Please check your inbox.');
        setError('');
      })
      .catch((error) => {
        setError('Failed to send password reset email. Please try again.');
        setResetMessage('');
      });
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Logo at the top */}
        <Logo />

        <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
          Welcome to Lubelink
        </Typography>

        <Box component="form" onSubmit={handleSignIn} sx={{ mt: 3 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          {resetMessage && (
            <Typography color="success" sx={{ mt: 2 }}>
              {resetMessage}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Link href="#" variant="body2" sx={{ display: 'block', textAlign: 'center' }} onClick={handlePasswordReset}>
            Forgot password?
          </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default SignIn;
