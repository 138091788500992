import React from 'react';
import { Box } from '@mui/material';
import EquipmentTable from '../components/EquipmentTable'; // Import the EquipmentTable component

function EquipmentPage() {
  return (
    <Box sx={{ p: 3 }}>
      <EquipmentTable />
    </Box>
  );
}

export default EquipmentPage;