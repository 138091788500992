import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button, Box, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { EquipmentController } from '../controllers/EquipmentController'; // Import the EquipmentController
import { formatDistanceToNowStrict } from 'date-fns'; // Import formatDistanceToNowStrict from date-fns
import QrScanner from 'react-qr-scanner'; // Import the QR Scanner

const initialNewEquipment = { unitNumber: '', manufacturer: '', model: '', uuid: '', status: 'Pending', lastSeen: null };

function EquipmentTable() {
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [newEquipment, setNewEquipment] = useState(initialNewEquipment);
  const [editingId, setEditingId] = useState(null); // Track the ID of the equipment being edited
  const [error, setError] = useState('');

  useEffect(() => {
    fetchEquipmentData();
  }, []);

  const fetchEquipmentData = async () => {
    try {
      const equipmentList = await EquipmentController.getAllEquipment();
      setData(equipmentList);
    } catch (error) {
      console.error('Error fetching equipment data:', error);
    }
  };

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setEditingId(id); // Set the ID of the equipment being edited
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddNewClick = () => {
    setEditingId(null); // Reset editing ID when adding a new item
    setNewEquipment(initialNewEquipment); // Reset the form
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setScannerOpen(false);
    setError('');
    setNewEquipment(initialNewEquipment); // Reset the form
  };

  const handleSaveEquipment = async () => {
    const { unitNumber, manufacturer, model, uuid } = newEquipment;

    if (!unitNumber || !manufacturer || !model || !uuid) {
      setError('All fields must be completed.');
      return;
    }

    try {
      if (editingId) {
        await EquipmentController.updateEquipment(editingId, newEquipment);
        console.log('Equipment updated successfully');
      } else {
        await EquipmentController.addEquipment(newEquipment);
        console.log('New equipment added successfully');
      }
      fetchEquipmentData(); // Refresh the table data
      handleDialogClose(); // Close the dialog
    } catch (error) {
      console.error('Error saving equipment:', error);
      setError('Failed to save equipment.');
    }
  };

  const handleDeleteEquipment = async () => {
    try {
      await EquipmentController.deleteEquipment(editingId);
      console.log('Equipment deleted successfully');
      fetchEquipmentData(); // Refresh the table data
      handleMenuClose(); // Close the menu
    } catch (error) {
      console.error('Error deleting equipment:', error);
    }
  };

  const handleEditEquipment = async () => {
    const equipmentToEdit = data.find(equipment => equipment.id === editingId);
    if (equipmentToEdit) {
      setNewEquipment(equipmentToEdit);
      setOpen(true);
    }
    handleMenuClose();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewEquipment((prev) => ({ ...prev, [name]: value }));
  };

  const handleScanQRCode = () => {
    setScannerOpen(true);
  };

  const handleScan = (result) => {
    if (result && result.text) {
      setNewEquipment((prev) => ({ ...prev, uuid: result.text }));
      setScannerOpen(false);
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  const renderCircle = (color) => (
    <Box sx={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: color, display: 'inline-block' }} />
  );

  const renderLastSeen = (lastSeen) => {
    if (!lastSeen) return 'Never';
    const duration = formatDistanceToNowStrict(new Date(lastSeen), { addSuffix: false });
    return <Typography variant="body2">{duration}</Typography>;
  };

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
        Equipment
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddNewClick}>
          Add New
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unit Number</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Pump On</TableCell>
              <TableCell>Alert</TableCell>
              <TableCell>Alarm</TableCell>
              <TableCell>Manual Lube</TableCell>
              <TableCell>UUID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Seen</TableCell>
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.unitNumber}</TableCell>
                <TableCell>{row.manufacturer}</TableCell>
                <TableCell>{row.model}</TableCell>
                <TableCell>{renderCircle(row.pumpOn ? 'green' : 'grey')}</TableCell>
                <TableCell>{renderCircle(row.alert ? 'orange' : 'grey')}</TableCell>
                <TableCell>{renderCircle(row.alarm ? 'red' : 'grey')}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary">Manual Lube</Button>
                </TableCell>
                <TableCell>{row.uuid}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{renderLastSeen(row.lastSeen)}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleMenuClick(event, row.id)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleEditEquipment}>Edit</MenuItem>
                    <MenuItem onClick={handleDeleteEquipment}>Delete</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{editingId ? 'Edit Equipment' : 'Add New Equipment'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="unitNumber"
            label="Unit Number"
            fullWidth
            value={newEquipment.unitNumber}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="manufacturer"
            label="Manufacturer"
            fullWidth
            value={newEquipment.manufacturer}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="model"
            label="Model"
            fullWidth
            value={newEquipment.model}
            onChange={handleChange}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              margin="dense"
              name="uuid"
              label="LubeLINK UUID"
              fullWidth
              value={newEquipment.uuid}
              onChange={handleChange}
            />
            <IconButton onClick={handleScanQRCode}>
              <CameraAltIcon />
            </IconButton>
          </Box>
          <TextField
            margin="dense"
            name="status"
            label="Status"
            fullWidth
            value="Pending"
            disabled
          />
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          {scannerOpen && (
            <Box sx={{ mt: 2 }}>
              <QrScanner
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%' }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSaveEquipment}>{editingId ? 'Save Changes' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EquipmentTable;
