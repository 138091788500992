import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, TextField, TablePagination, Chip, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import AlarmIcon from '@mui/icons-material/Alarm';
import PowerIcon from '@mui/icons-material/Power';

function getEventTypeChip(eventType) {
  switch (eventType) {
    case 'Alert':
      return <Chip icon={<WarningIcon />} label="Alert" color="warning" />;
    case 'Alarm':
      return <Chip icon={<AlarmIcon />} label="Alarm" color="error" />;
    case 'Pump On':
      return <Chip icon={<PowerIcon />} label="Pump On" color="success" />;
    case 'Pump Off':
      return <Chip icon={<PowerIcon />} label="Pump Off" color="default" />;
    case 'Power On':
      return <Chip icon={<PowerIcon />} label="Power On" color="primary" />;
    case 'Power Off':
      return <Chip icon={<PowerIcon />} label="Power Off" color="default" />;
    default:
      return <Chip label={eventType} />;
  }
}

function Alerts() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [eventTypeFilter, setEventTypeFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchEventData();
  }, []);

  const fetchEventData = async () => {
    // Dummy data entries with all possible conditions
    const dummyData = [
      { date: new Date().toISOString(), eventType: 'Power On', uuid: '12345', unitNumber: 'Unit001', manufacturer: 'ABC Corp', model: 'X1000', powerOn: true, powerOff: false, pumpOn: false, pumpOff: false, alert: false, alarm: false },
      { date: new Date().toISOString(), eventType: 'Power Off', uuid: '23456', unitNumber: 'Unit002', manufacturer: 'XYZ Inc', model: 'Z2000', powerOn: false, powerOff: true, pumpOn: false, pumpOff: false, alert: false, alarm: false },
      { date: new Date().toISOString(), eventType: 'Pump On', uuid: '34567', unitNumber: 'Unit003', manufacturer: 'DEF Ltd', model: 'Y3000', powerOn: false, powerOff: false, pumpOn: true, pumpOff: false, alert: false, alarm: false },
      { date: new Date().toISOString(), eventType: 'Pump Off', uuid: '45678', unitNumber: 'Unit004', manufacturer: 'GHI LLC', model: 'A4000', powerOn: false, powerOff: false, pumpOn: false, pumpOff: true, alert: false, alarm: false },
      { date: new Date().toISOString(), eventType: 'Alert', uuid: '56789', unitNumber: 'Unit005', manufacturer: 'JKL Co', model: 'B5000', powerOn: false, powerOff: false, pumpOn: false, pumpOff: false, alert: true, alarm: false },
      { date: new Date().toISOString(), eventType: 'Alarm', uuid: '67890', unitNumber: 'Unit006', manufacturer: 'MNO Inc', model: 'C6000', powerOn: false, powerOff: false, pumpOn: false, pumpOff: false, alert: false, alarm: true },
    ];

    setData(dummyData);
    setFilteredData(dummyData);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    filterData(query, eventTypeFilter);
  };

  const handleEventTypeFilterChange = (event) => {
    const filter = event.target.value;
    setEventTypeFilter(filter);
    filterData(searchQuery, filter);
  };

  const filterData = (query, eventType) => {
    let filtered = data;

    if (query) {
      filtered = filtered.filter(item =>
        item.unitNumber.toLowerCase().includes(query)
      );
    }

    if (eventType) {
      filtered = filtered.filter(item =>
        item.eventType === eventType
      );
    }

    setFilteredData(filtered);
    setPage(0); // Reset to the first page on search or filter
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Alerts</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <TextField
          label="Search by Unit Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ mr: 2 }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Event Type</InputLabel>
          <Select
            value={eventTypeFilter}
            onChange={handleEventTypeFilterChange}
            label="Event Type"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Alert">Alert</MenuItem>
            <MenuItem value="Alarm">Alarm</MenuItem>
            <MenuItem value="Pump On">Pump On</MenuItem>
            <MenuItem value="Pump Off">Pump Off</MenuItem>
            <MenuItem value="Power On">Power On</MenuItem>
            <MenuItem value="Power Off">Power Off</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell>UUID</TableCell>
              <TableCell>Unit Number</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Power On</TableCell>
              <TableCell>Power Off</TableCell>
              <TableCell>Pump On</TableCell>
              <TableCell>Pump Off</TableCell>
              <TableCell>Alert</TableCell>
              <TableCell>Alarm</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{new Date(row.date).toLocaleString()}</TableCell>
                  <TableCell>{getEventTypeChip(row.eventType)}</TableCell>
                  <TableCell>{row.uuid}</TableCell>
                  <TableCell>{row.unitNumber}</TableCell>
                  <TableCell>{row.manufacturer}</TableCell>
                  <TableCell>{row.model}</TableCell>
                  <TableCell>{row.powerOn ? '1' : '0'}</TableCell>
                  <TableCell>{row.powerOff ? '1' : '0'}</TableCell>
                  <TableCell>{row.pumpOn ? '1' : '0'}</TableCell>
                  <TableCell>{row.pumpOff ? '1' : '0'}</TableCell>
                  <TableCell>{row.alert ? '1' : '0'}</TableCell>
                  <TableCell>{row.alarm ? '1' : '0'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default Alerts;
