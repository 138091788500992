import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

function DashboardCard({ title, value, icon }) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon && (
            <Box sx={{ mr: 2 }}>
              {icon}
            </Box>
          )}
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h3" component="div">
            {value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default DashboardCard;
