import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Box, Tooltip, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { Dashboard as DashboardIcon, Build as EquipmentIcon, Notifications as AlertsIcon, People as UsersIcon, Menu as MenuIcon } from '@mui/icons-material';

function NavigationBar() {
  const [isDrawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isDrawerOpen ? 240 : 60,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isDrawerOpen ? 240 : 60,
          boxSizing: 'border-box',
          top: '64px', // Adjust based on the height of your AppBar
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
        {/* Hamburger Menu Button */}
        <IconButton onClick={handleDrawerToggle} sx={{ alignSelf: isDrawerOpen ? 'flex-end' : 'center', mb: 1 }}>
          <MenuIcon />
        </IconButton>
        
        {/* Divider after Hamburger Menu */}
        <Divider sx={{ width: '100%', mb: 1 }} />

        {/* Navigation Icons with Links */}
        <List sx={{ width: '100%' }}>
          <Tooltip title="Dashboard" placement="right" disableHoverListener={isDrawerOpen}>
            <ListItem button component={Link} to="/dashboard">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              {isDrawerOpen && <ListItemText primary="Dashboard" />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Equipments" placement="right" disableHoverListener={isDrawerOpen}>
            <ListItem button component={Link} to="/equipments">
              <ListItemIcon>
                <EquipmentIcon />
              </ListItemIcon>
              {isDrawerOpen && <ListItemText primary="Equipments" />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Alerts" placement="right" disableHoverListener={isDrawerOpen}>
            <ListItem button component={Link} to="/alerts">
              <ListItemIcon>
                <AlertsIcon />
              </ListItemIcon>
              {isDrawerOpen && <ListItemText primary="Alerts" />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Users" placement="right" disableHoverListener={isDrawerOpen}>
            <ListItem button component={Link} to="/users">
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              {isDrawerOpen && <ListItemText primary="Users" />}
            </ListItem>
          </Tooltip>
        </List>
      </Box>
    </Drawer>
  );
}

export default NavigationBar;
