import React from 'react';
import { Typography, Box } from '@mui/material';
import UsersTable from '../components/UsersTable'; // Ensure the path is correct based on your folder structure

function Users() {
  return (
    <Box sx={{ p: 3 }}>
      <UsersTable /> {/* This is where the UsersTable component is added */}
    </Box>
  );
}

export default Users;
