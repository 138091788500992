import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, Menu, MenuItem, Avatar, Switch, Typography } from '@mui/material'; // Add Typography here
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import { auth } from '../firebaseConfig'; // Import your auth object
import { signOut } from 'firebase/auth';
import Logo from './Logo'; // Import your Logo component

function MyAppBar({ toggleTheme }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMenuOpen = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('User signed out');
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  return (
    <AppBar position="fixed"> {/* Changed to "fixed" */}
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Logo />
        </Box>
        <IconButton color="inherit" onClick={handleDrawerOpen}>
          <NotificationsIcon />
        </IconButton>
        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
          <Avatar alt="User Avatar" src="/static/images/avatar/1.jpg" />
        </IconButton>
      </Toolbar>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerClose}>
          <Typography variant="h6" sx={{ padding: 2 }}>
            Notifications
          </Typography>
          <List>
            <ListItem button>
              <ListItemText primary="Notification 1" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Notification 2" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        keepMounted
      >
        <MenuItem>
          <Typography variant="body1">Profile</Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1">Settings</Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1">Toggle Theme</Typography>
          <Switch checked={theme.palette.mode === 'dark'} onChange={toggleTheme} />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ marginRight: 1 }} />
          <Typography variant="body1">Logout</Typography>
        </MenuItem>
      </Menu>
    </AppBar>
  );
}

export default MyAppBar;
