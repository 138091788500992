import React from 'react';
import { Box, Toolbar } from '@mui/material';
import MyAppBar from './AppBar';
import NavigationBar from './NavigationBar';

function MainLayout({ children, toggleTheme }) {
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* AppBar should be fixed at the top */}
      <MyAppBar toggleTheme={toggleTheme} />
      
      {/* Layout below the AppBar */}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {/* Sidebar (NavigationBar) on the left */}
        <NavigationBar />
        
        {/* Main content area */}
        <Box component="main" sx={{ flexGrow: 1, p: 3, mt: '64px' }}> {/* mt to push content below AppBar */}
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default MainLayout;
