import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import DashboardCard from '../components/DashboardCard';
import DevicesIcon from '@mui/icons-material/Devices';
import WarningIcon from '@mui/icons-material/Warning';
import AlarmIcon from '@mui/icons-material/Alarm';
import ChartComponent from '../components/ChartComponent';
import EventsTable from '../components/EventsTable'; // Ensure correct import

function Dashboard() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title="Units Deployed" value="10" icon={<DevicesIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title="Active Alerts" value="3" icon={<WarningIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title="Active Alarms" value="1" icon={<AlarmIcon />} />
        </Grid>

        <Grid item xs={12}>
          <ChartComponent />
        </Grid>

        {/* Events Table */}
        <Grid item xs={12}>
          <EventsTable />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
