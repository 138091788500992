import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Assuming you've added Firestore to your firebaseConfig.js

const usersCollectionRef = collection(db, 'users');

// Create a new user with default values for notifications, role, and status
export const createUser = async (userData) => {
  try {
    const userWithDefaults = {
      firstName: userData.firstName || '', // User's first name
      lastName: userData.lastName || '', // User's last name
      email: userData.email || '', // User's email address
      phoneNumber: null, // Initializing phone number to null
      role: 'User', // Default role
      status: 'Pending', // Default status
      notifications: {
        emailAlerts: false, // Default alert notifications
        smsAlerts: false,
        emailAlarms: false, // Default alarm notifications
        smsAlarms: false,
        emailEvents: false, // Default event notifications (email)
        smsEvents: false, // Default event notifications (SMS)
      },
    };

    const docRef = await addDoc(usersCollectionRef, userWithDefaults);
    console.log('User created with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding user: ', e);
  }
};

// Get all users
export const getUsers = async () => {
  try {
    const querySnapshot = await getDocs(usersCollectionRef);
    const usersList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return usersList;
  } catch (e) {
    console.error('Error getting users: ', e);
    return [];
  }
};

// Update a user by ID
export const updateUser = async (userId, updatedData) => {
  try {
    const userDoc = doc(db, 'users', userId);
    await updateDoc(userDoc, updatedData);
    console.log('User updated with ID: ', userId);
  } catch (e) {
    console.error('Error updating user: ', e);
  }
};

// Delete a user by ID
export const deleteUser = async (userId) => {
  try {
    const userDoc = doc(db, 'users', userId);
    await deleteDoc(userDoc);
    console.log('User deleted with ID: ', userId);
  } catch (e) {
    console.error('Error deleting user: ', e);
  }
};
